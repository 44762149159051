html {
  background: #000;
  padding: 0;
  margin: 0; }

body {
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
  background: transparent;
  font-size: 16px;
  letter-spacing: .075em;
  font-weight: 300;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 0;
  margin: 0; }

a {
  color: #fff;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    color: #fff;
    text-decoration: none; }

ul {
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

h2 {
  margin: 0;
  padding: 5px 0; }

h3 {
  font-size: 16px;
  padding: 0;
  margin: 0; }

.list-unstyled {
  list-style: none; }

.list-inline {
  list-style: none; }
  .list-inline > li {
    display: inline-block; }

.lightbox .lb-image {
  border-color: transparent;
  border-radius: 6px; }

.lb-outerContainer {
  background: transparent; }

.container {
  width: 100%;
  padding-left: 300px;
  box-sizing: border-box;
  text-align: center; }

.col-left {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 100%;
  width: 300px;
  z-index: 10; }
  .col-left > .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-top: 70px; }

.col-right {
  position: relative;
  margin: 0 auto;
  width: 1000px;
  max-width: 100%;
  padding: 300px 200px 60px 60px;
  box-sizing: border-box;
  text-align: left;
  min-height: 100vh; }

@media (max-width: 1100px) {
  .col-right {
    padding: 300px 100px 60px 10px; } }

@media (max-width: 768px) {
  .container {
    padding-left: 0; }
  .col-left {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 20px 40px;
    height: 230px; }
    .col-left > .inner {
      flex-direction: row;
      justify-content: space-between; }
      .col-left > .inner nav {
        margin-top: 0;
        width: auto; }
  .col-right {
    padding: 60px 40px;
    min-height: calc(100vh - 230px); } }

.logo {
  display: block; }

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000 url("../img/bg/khandl-bg.jpg") no-repeat center/cover;
  opacity: .6;
  z-index: -1; }

.text-center {
  text-align: center; }

.my-hr {
  margin: 60px 0 5px 0; }

.home-text {
  margin-top: 0;
  max-width: 100%; }

nav {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: .14em;
  margin-top: 80px;
  font-weight: 600;
  width: 150px; }
  nav ul li {
    padding: 5px 0; }
    nav ul li a, nav ul li a:hover, nav ul li a:focus, nav ul li a:active, nav ul li a:visited {
      color: #fff;
      background: transparent;
      text-decoration: none; }
    nav ul li a.active, nav ul li a:hover {
      color: #ff8d8e; }

.margin-top-nav {
  margin-top: 85px; }

.list-contact {
  font-size: 24px;
  margin-top: 5%; }
  .list-contact a {
    text-transform: lowercase; }
    .list-contact a:hover, .list-contact a:active, .list-contact a:focus, .list-contact a:visited {
      color: #ff8d8e; }

.list-focus li {
  padding-bottom: 10px; }
  .list-focus li:before {
    content: '- '; }

#logo {
  width: 150px; }

#kahostav-logo {
  margin: 0 50px 0 0;
  width: 208px; }

#list-photo {
  position: relative;
  width: 100%;
  padding: 0;
  margin: -1% 0 0 0;
  display: flex;
  flex-wrap: wrap; }
  #list-photo > li {
    position: relative;
    width: 32%;
    display: block;
    overflow: hidden;
    margin: 0 2% 2% 0; }
    #list-photo > li:nth-child(3n) {
      margin-right: 0; }
    #list-photo > li a {
      width: 100%;
      display: block;
      height: 0;
      padding-bottom: 66.6%;
      overflow: hidden; }
      #list-photo > li a img {
        width: 100%;
        height: auto; }
  @media (max-width: 768px) {
    #list-photo > li {
      width: 49%;
      margin-right: 2%; }
      #list-photo > li:nth-child(3n) {
        margin-right: 2%; }
      #list-photo > li:nth-child(2n) {
        margin-right: 0; } }

#tabs-navigation {
  margin: 0 0 40px 0; }
  #tabs-navigation li a {
    padding: 15px 5px; }
    #tabs-navigation li a.active {
      color: #ff8d8e; }

@media (max-width: 768px) {
  #logo {
    width: 135px; } }
